<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <widget-congratulation :data="data.congratulations" />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal
          icon="DollarSignIcon"
          :statistic="analytics.cashbankBalance.cash.balance_view"
          statistic-title="Cash"
        />
      </b-col>
      <b-col lg="4">
        <statistic-card-horizontal
          icon="CreditCardIcon"
          :statistic="analytics.cashbankBalance.bank.balance_view"
          statistic-title="Bank"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Revenue Report Card -->
      <b-col lg="8">
        <widget-revenue-report :data="analytics.revExpBalance" />
      </b-col>
      <!--/ Revenue Report Card -->

      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <!-- <b-col lg="6" md="3" cols="6">
            <widget-order-chart :data="data.statisticsOrder" />
            <statistic-card-vertical
              color="danger"
              icon="ShoppingBagIcon"
              :statistic="analytics.orders.orders_view"
              statistic-title="Orders"
            />
          </b-col> -->
          <!--/ Bar Chart - Orders -->
          <b-col lg="12" md="3" cols="12">
            <widget-profit-chart :data="analytics.profitBalance" />
          </b-col>
          <b-col lg="12" md="6">
            <widget-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
// import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

// import { getUserData } from '@/auth/utils'
import WidgetCongratulation from './WidgetCongratulation.vue'
import WidgetRevenueReport from './WidgetRevenueReport.vue'
// import WidgetOrderChart from './WidgetOrderChart.vue'
import WidgetProfitChart from './WidgetProfitChart.vue'
import WidgetEarningsChart from './WidgetEarningsChart.vue'
import { formatCurrency } from '@/utils/formatter'

// import WidgetCompanyTable from './WidgetCompanyTable.vue'
// import WidgetTransactions from './WidgetTransactions.vue'

export default {
  name: 'Analytics',
  components: {
    BRow,
    BCol,

    WidgetCongratulation,
    StatisticCardHorizontal,
    // StatisticCardVertical,
    WidgetRevenueReport,
    // WidgetOrderChart,
    WidgetProfitChart,
    WidgetEarningsChart
    // WidgetCompanyTable,
    // WidgetTransactions
  },
  data () {
    return {formatCurrency,
      data: {
        statisticsCashbank: {
          cash: 'Rp. 300.000.000',
          bank: 'Rp. 800.000.000'
        },
        statisticsOrder: {
          series: [
            {
              name: '2020',
              data: [45, 85, 65, 45, 65]
            }
          ]
        },
        statisticsProfit: {
          series: [
            {
              data: [0, 20, 5, 30, 15, 45]
            }
          ]
        },
        earningsChart: {
          series: [53, 16, 31]
        },
        revenue: {
          years: ['2020', '2019', '2018'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: 'Earning',
                data: [
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 0,
                    x: 'Jan'
                  },
                  {
                    y: 10000000,
                    x: 'Jan'
                  },
                  {
                    y: 12500000,
                    x: 'Jan'
                  },
                  {
                    y: 9000000,
                    x: 'Jan'
                  },
                  {
                    y: 8000000,
                    x: 'Jan'
                  },
                  {
                    y: 25000000,
                    x: 'Jan'
                  }
                ]
              },
              {
                name: 'Expense',
                data: [
                  { y: -2500000, x: 'Jan' },
                  { y: -3000000, x: 'Jan' },
                  { y: -2000000, x: 'Jan' },
                  { y: -3500000, x: 'Jan' },
                  { y: -3500000, x: 'Jan' },
                  { y: -5000000, x: 'Jan' },
                  { y: -5400000, x: 'Jan' },
                  { y: -4000000, x: 'Jan' },
                  { y: -6500000, x: 'Jan' },
                  { y: -4500000, x: 'Jan' },
                  { y: -4200000, x: 'Jan' },
                  { y: -10000000, x: 'Jan' }
                ]
              }
            ]
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62]
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27]
              }
            ]
          }
        },
        companyTable: [
          {
            avatarImg: require('@/assets/images/icons/toolbox.svg'),
            title: 'Dixons',
            subtitle: 'meguc@ruj.io',
            avatarIcon: 'MonitorIcon',
            avatarColor: 'light-primary',
            avatarTitle: 'Technology',
            viewTitle: '23.4k',
            viewsub: 'in 24 hours',
            revenue: '891.2',
            sales: '68',
            loss: true
          },
          {
            avatarImg: require('@/assets/images/icons/parachute.svg'),
            title: 'Motels',
            subtitle: 'vecav@hodzi.co.uk',
            avatarIcon: 'CoffeeIcon',
            avatarColor: 'light-success',
            avatarTitle: 'Grocery',
            viewTitle: '78k',
            viewsub: 'in 2 days',
            revenue: '668.51',
            sales: '97',
            loss: false
          },
          {
            avatarImg: require('@/assets/images/icons/brush.svg'),
            title: 'Zipcar',
            subtitle: 'davcilse@is.gov',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'Fashion',
            viewTitle: '162',
            viewsub: 'in 5 days',
            revenue: '522.29',
            sales: '62',
            loss: false
          },
          {
            avatarImg: require('@/assets/images/icons/star.svg'),
            title: 'Owning',
            subtitle: 'us@cuhil.gov',
            avatarIcon: 'MonitorIcon',
            avatarColor: 'light-primary',
            avatarTitle: 'Technology',
            viewTitle: '214',
            viewsub: 'in 24 hours',
            revenue: '291.01',
            sales: '88',
            loss: false
          },
          {
            avatarImg: require('@/assets/images/icons/book.svg'),
            title: 'Cafés',
            subtitle: 'pudais@jife.com',
            avatarIcon: 'CoffeeIcon',
            avatarColor: 'light-success',
            avatarTitle: 'Grocery',
            viewTitle: '208',
            viewsub: 'in 1 week',
            revenue: '783.93',
            sales: '16',
            loss: true
          },
          {
            avatarImg: require('@/assets/images/icons/rocket.svg'),
            title: 'Kmart',
            subtitle: 'bipri@cawiw.com',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'Fashion',
            viewTitle: '990',
            viewsub: 'in 1 month',
            revenue: '780.05',
            sales: '78',
            loss: false
          },
          {
            avatarImg: require('@/assets/images/icons/speaker.svg'),
            title: 'Payers',
            subtitle: 'luk@izug.io',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'Fashion',
            viewTitle: '12.9k',
            viewsub: 'in 12 hours',
            revenue: '531.49',
            sales: '42',
            loss: false
          }
        ],
        transactionData: [
          {
            mode: 'Wallet',
            types: 'Starbucks',
            avatar: 'PocketIcon',
            avatarVariant: 'light-primary',
            payment: '-$74',
            deduction: true
          },
          {
            mode: 'Bank Transfer',
            types: 'Add Money',
            avatar: 'CheckIcon',
            avatarVariant: 'light-success',
            payment: '+$480',
            deduction: false
          },
          {
            mode: 'Paypal',
            types: 'Add Money',
            avatar: 'DollarSignIcon',
            avatarVariant: 'light-danger',
            payment: '+$480',
            deduction: false
          },
          {
            mode: 'Mastercard',
            types: 'Ordered Food',
            avatar: 'CreditCardIcon',
            avatarVariant: 'light-warning',
            payment: '-$23',
            deduction: true
          },
          {
            mode: 'Transfer',
            types: 'Refund',
            avatar: 'TrendingUpIcon',
            avatarVariant: 'light-info',
            payment: '+$98',
            deduction: false
          }
        ],
        congratulations: {
          name: 'Fathan',
          saleToday: '55k'
        }
      },
      analytics: {
        cashbankBalance: {
          cash: { balance_view: '0' },
          bank: { balance_view: '0' }
        },
        revExpBalance: [],
        orders: {
          orders: 0,
          orders_view: 0
        },
        profitBalance: []
      }
    }
  },
  created () {
    // data
    this.$http
      .get('/reports/dashboard?dateStart=01/01/2021&dateEnd=31/10/2021')
      .then((response) => {


        this.analytics.cashbankBalance.bank.balance_view = formatCurrency(response.data.dataBank)
        this.analytics.cashbankBalance.cash.balance_view = formatCurrency(response.data.dataCash)


        // this.analytics                    = response.data

        // console.log(this)

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        // const userData = getUserData()
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
